<template>
  <ui-header>
    Истекающие
    <template #right>{{`${store.currentPath?.pointAbv}${store.currentPath?.partnerPointUid ? ` (${store.currentPath?.partnerPointUid})` : ''}` }}</template>
  </ui-header>
  <ui-main v-loading="isLoading">
    <el-form ref="formRef" :model="searchForm" @submit.prevent="searchOrder()">
      <el-form-item prop="orderId">
        <el-input style="width: 100%; max-width: 100%;" clearable placeholder="Введите номер заказа" size="large" class="search-input" v-model="searchForm.orderId"/>
        <div class="flex-box">
          <el-button @click="searchOrder()" type="primary" size="large" :disabled="isLoading" :loading="isLoading"
            :style="'min-width: 116px'">
            Поиск
          </el-button>
          <el-button @click="clearForm" type="default" size="large" :disabled="isLoading" :loading="isLoading"
            :style="'min-width: 116px'">
            Сбросить
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <div class="items-center-column">
      <el-table :data="elements.data" :border="true" empty-text="Нет данных" table-layout="auto"
        @sort-change="sortChange">
        <el-table-column sortable="custom" prop="orderId" label="Номер Заказа" />
        <!-- <el-table-column sortable="custom" prop="quantity" label="Мест" /> -->
        <el-table-column sortable="custom" prop="created" label="Дата создания">
          <template #default="{ row }">
            {{ formatDate(row.created) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="pointAbv" label="ПВЗ назначения" />
        <el-table-column sortable="custom" prop="statusName" label="Статус заказа" />
        <el-table-column sortable="custom" prop="statusModtime" label="Дата статуса">
          <template #default="{ row }">
            {{ formatDate(row.statusModtime) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="daysLeft" label="Осталось дней" />
        <el-table-column >
          <template #default="{ row }">
            <order-return @update="orderReturn" :key="row.orderId" :orderId="row.orderId" :pointAbv="row.pointAbv"></order-return>
          </template>
        </el-table-column>
      </el-table>
      <ui-pagination v-if="elements.total > elements.data.length && !isSearch" :total="elements.total" :current-page="page"
        @change="setPage" />
    </div>
  </ui-main>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import { getExpiringOrders } from '@/api';
import UiHeader from '@/components/ui/ui-header.vue';
import UiMain from '@/components/ui/ui-main.vue';
import UiPagination from '@/components/ui/ui-pagination.vue';
import { useGetElements } from '@/composables/use-get-elements';
import { dateFormat } from '@/utils/date-format';
import { useUserStore } from '@/store/use-user-store';
import { DEFAULT_PARAMS } from '@/utils/constants';
import { ExpiringDto } from '@/models/api/expiring-dto.interface';
import OrderReturn from '@/views/expiring/order-return.vue'
import { ElNotification } from 'element-plus';

export default defineComponent({
  name: 'expiring',
  components: {
    UiPagination,
    UiMain,
    UiHeader,
    OrderReturn
  },
  setup() {

    const { isLoading, page, setPage, elements, getElements, sortChange } =
      useGetElements<ExpiringDto>(getExpiringOrders);

    const formatDate = (date: string) => dateFormat(date);

    const store = useUserStore();

    const isSearch = ref(false)

    const searchForm = ref({
      orderId: '',
    });

    const searchOrder = () => {
      isSearch.value = true;
      getElements({
        orderIdFilter: searchForm.value.orderId,
        ...DEFAULT_PARAMS
      })
    }
    const orderReturn =() =>{
      getElements();
      ElNotification({ position: 'bottom-right', type: 'success', message: 'Успешно!' });
    }

    const clearForm = () =>{
      getElements();
      searchForm.value.orderId = ''
      isSearch.value = false
    }

    return {
      isLoading,
      elements,
      page,
      setPage,
      getElements,
      store,
      formatDate,
      searchForm,
      searchOrder,
      sortChange,
      orderReturn,
      clearForm,
      isSearch
    };
  },
});
</script>

<style lang="stylus" scoped>

</style>
